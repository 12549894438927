.has-large-font-size {
  line-height: 1.1;
}

.front-page__login {
  position: relative;
  background-size: cover;
  background-position: 50%;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 80vh;
}
.myheartcoach-bg {
  background-image: url("../../assets/myHeartCoachBG.jpg");
}
.empower-bg {
  background-image: url("../../assets/empowerBG.png");
}
.opal-bg {
  background-image: url("../../assets/opalBG.png");
}
.prehab4all-bg {
  background-image: url("../../assets/prehab4AllBG.png");
}
.strive-bg {
  background-image: url("../../assets/striveBG.jpg");
}
.peace-bg {
  background-image: url("../../assets/peaceBG.jpg");
}
.wellness-card__header {
  text-align: center;
}
.front-page__login:after {
  display: block;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(116, 27, 220, 0.2);
}

.front-page__login-card.tribe-common-g-row.tribe-events-widget-events-list__event-row,
.front-page__login-card.wellness-card {
  background-color: hsla(0, 0%, 100%, 0.9);
  position: relative;
  z-index: 5;
}

.front-page > div {
  -ms-flex: 1;
  flex: 1;
}

.front-page__login {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  padding: 2rem;
}

@media only screen and (min-width: 1200px) {
  .front-page__login {
    padding: 3rem 2rem 2rem;
  }
}

.front-page__login .gform_wrapper {
  min-width: 300px;
  max-width: 450px;
}

@media only screen and (min-width: 1200px) {
  .front-page__login .gform_wrapper {
    width: 400px;
  }
}

.front-page__logged-in-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 300px;
  color: #40359b;
  text-align: center;
}

.front-page__logged-in-card .button,
.front-page__logged-in-card .resource-search__button {
  display: inline-block;
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.front-page footer {
  padding-top: 1rem;
  border-top: 1px solid #cecece;
}

.front-page__help-link {
  color: #6e6e6e;
  font-size: 14px;
}

.front-page__text {
  padding: 2rem;
}

.front-page .white {
  color: #fff;
}

.front-page .white a {
  color: #cb49f7;
}

.front-page .wordpress-login-form-wrapper {
  min-width: 300px;
}

@media only screen and (min-width: 992px) {
  .front-page .wordpress-login-form-wrapper {
    width: 400px;
  }
}

.front-page .wordpress-login-form-wrapper p {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.front-page .wordpress-login-form-wrapper label {
  font-weight: 700;
}

.front-page .wordpress-login-form-wrapper input {
  border-radius: 10px;
  border-style: solid;
}

form {
  background-color: hsla(0, 0%, 100%, 0.9);
  position: relative;
  z-index: 5;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  min-width: 300px;
  .form-group {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 1rem;
    .error-message {
      color: red;
      max-width: 300px;
      margin: 0;
    }
    label {
      font-weight: 700;
      display: inline-block;
      margin-bottom: 0.25rem;
    }

    input {
      border-radius: 10px;
      // border-style: solid;
      border-width: 2px;
      overflow: visible;
      margin: 0;
      padding-block: 1px;
      padding-inline: 2px;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      padding: 6px;
    }

    .password-div {
      background-color: #fff;
      border-style: solid;
      border-width: 2px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      input {
        width:100%;
        border: none;
      }
      input:focus{
        outline: none;
      }

      &:focus-within{
      border-width: 3px;
      }
    }

    .tooltip {
      position: relative;
      display: inline-block;
    }
    
    .tooltip::after {
      content: attr(title);
      position: absolute;
      bottom: -160%;
      left: 50%;
      height: 20px;
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(-50%);
      background-color: #c1ccf0;
      color: #0e0d0d;
      padding: 0.5rem;
      border-radius: 4px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease, visibility 0.2s ease;
    }
    
    .tooltip:hover::after {
      opacity: 1;
      visibility: visible;
    }

    #termsCheckbox {
      height: 20px;
      width: 20px;
      margin-right: 8px;
      vertical-align: bottom;
      position: relative;
      top: -1px;
    }
    .terms-label {
      margin-bottom: 0;
    }
  }
  .form-login {
    .error-message {
      margin: 0;
    }
  }
  .form-group__email {
    color: rgba(0, 0, 0, 0.38);
  }
  .form-group__terms {
    display: inline-block;
    position: relative;
    .terms-link {
      cursor: pointer;
      color: blue; // Customize link color
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .forgot-password-link {
    cursor: pointer;
    color: #007bff;
    text-decoration: underline;
    margin: 10px 0;

    &:hover {
      text-decoration: none;
      color: #0056b3;
    }
  }
  .reset-pwd-btn {
    margin-bottom: 10px !important;
  }
  .back-to-login-btn {
    cursor: pointer;
    background-color: #5700bb;
    margin: 10px 0;
    color: #fff;
    border: 0;
    border-radius: 10px;
    border-style: solid;
    margin: 0 auto 10px;
    display: block;
    padding: 0.5em 1.2em;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-size: 15px;
    &__reset {
      width: 30%;
    }
  }
  .terms-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-height: 500px;
    overflow-y: auto;
    z-index: 1000;
    h1 {
      font-size: 16px;
    }

    button {
      margin-top: 10px;
      width: 100px;
      float: right;
    }
  }
  button {
    display: block;
    width: 100%;
    padding: 0.5em 1.2em;
    font-weight: 500;
    line-height: 1.4;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #5700bb;
    border: 0;
    border-radius: 4px;
    border-radius: 10px;
    border-style: solid;
    margin: 0 auto;
    opacity: 1;
  }
  button:disabled {
    opacity: 0.3 !important;
    cursor: not-allowed;
  }
  .terms-error {
    color: #ff0000;
  }
}

/* Remember Me checkbox container */
.form-group__remember-me {
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-direction: row !important;
  gap: 4px;
}

/* Remember Me checkbox */
.form-group__remember-me input {
  cursor: pointer;
}

/* Remember Me label */
.remember-me-label {
  font-size: 14px;
  color: #333; /* Adjust the color as needed */
  cursor: pointer;
}
